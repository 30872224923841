
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { creditRatingDrop, getCreditApprovalList, creditApproval } from '@/api/creditRatingManagement'
import { getProvinceList, getCityList, getCountyList, getTownshipList } from '@/api/peasantInfoManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class CreditViewApproval extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private creditRatingOptions: any = []
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    householder: '',
    creditRatingId: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private requestEnd: boolean = true
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'CreditViewDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.provinceOptions.length) {
        getProvinceList().then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      if (!vim.creditRatingOptions.length) {
        creditRatingDrop({}).then((res) => {
          if (res.data.data && res.data.data.ratings) {
            vim.creditRatingOptions = res.data.data.ratings
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'CreditViewDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions,
        creditRatingOptions: this.creditRatingOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    getCreditApprovalList(params).then((res) => {
      if (res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private changeFn(row: any, type: any) {
    let contentText = '是否确认通过人员查看信用评定结果申请？'
    if (type != 1) {
      contentText = '是否确认拒绝人员查看信用评定结果申请？'
    }
    this.$confirm(contentText, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        creditApproval({
          id: row.id,
          isShow: type
        })
          .then((res) => {
            if (res.data && res.data.code == 1000) {
              this.getList()
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
  // 搜索信用查看审批
  private handleSearch() {
    this.getList()
  }
  // 查看申请审批
  private handleDetails(row: any) {
    this.$router.push({
      name: 'CreditViewDetails',
      params: {
        id: row.id,
        creditAssessorRelId: row.creditAssessorRelId
      }
    })
  }
}
